const initialState = {
    educationData: [],
    videoList: [],
    documentList: [],
    educationDataUnit: [],
    educationDataVideo: [],
    getCategoryUnitContentDetail: {},
    videoLoadingData:true
}

const EducationReducers = (state = initialState, action) => {
    switch (action.type) {
        case "getEducationServiceById":
            return {
                ...state,
                videoList: action.videoList,
                documentList: action.documentList,
                videoLoadingData:action.videoLoadingData

            };
        case "GET_CATEGORY_UNIT_CONTENT_BY_ID":
            return {
                ...state,
                getCategoryUnitContentDetail: action.getCategoryUnitContentDetail

            };

        case "getEducationService":
            return { ...state, educationDataUnit: action.alldataunit }

        case "getEducationServiceVideo":
            return { ...state, educationDataVideo: action.alldatavideo }
        case 'RESET_VIDEO':
            return { ...state, videoList: undefined };
        default:
            return state;
    }
}

export default EducationReducers;