// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import FeedbackReducers from 'redux/Feedback/reducer'
import AnnouncementReducers from 'redux/Announcement/reducer'
import CategoryReducers from 'redux/Category/reducer'
import CategoryUnitReducers from 'redux/CategoryUnit/reducer'
import UnitReducers from 'redux/Unit/reducer'
import UserReducers from 'redux/User/reducer'
import UnitTitleReducers from 'redux/UnitTitle/reducer'
import UserAnnouncementReducers from 'redux/UserAnnouncement/reducer'
import EducationReducers from 'redux/Education/reducer'
import TestReducers from 'redux/Test/reducer'
import ReportReducers from 'redux/Report/reducer'
import UnitCategoryUnitContentReducers from 'redux/UnitCategoryUnitContent/reducer'


const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  FeedbackReducers,
  AnnouncementReducers,
  CategoryReducers,
  CategoryUnitReducers,
  UnitReducers,
  UserReducers,
  UnitTitleReducers,
  UserAnnouncementReducers,
  EducationReducers,
  TestReducers,
  ReportReducers,
  UnitCategoryUnitContentReducers
})

export default rootReducer
