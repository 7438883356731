const initialState = {
    testData:[],
    testDataById:[]
}

const TestReducers = (state = initialState,action)=>{
    switch(action.type){
        case "GetAllTest":
            return {...state,testData:action.alldata};
        case "GetTestById":
            return {...state,testDataById:action.alldata};
        default:
        return state;
    }
}

export default TestReducers;