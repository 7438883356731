const initialState = {
    userAnnouncementData:[],
    userAnnouncementLoadingData:true
}

const UserAnnouncementReducers = (state = initialState, action) => {
    switch (action.type) {
        case "GetAnnouncementByUser":
            return { ...state, userAnnouncementData: action.alldata,userAnnouncementLoadingData:action.userAnnouncementLoadingData };
        default:
            return state;
    }
}

export default UserAnnouncementReducers;