const initialState = {
    lmsPanelUserData: [],
    userIdData: [],
    userLoadingData:true
}

const UserReducers = (state = initialState, action) => {
    switch (action.type) {
        case "GetAllUser":
            return { ...state, lmsPanelUserData: action.alldata,userLoadingData:action.userLoadingData };
        case "GetUserById":
            return { ...state, userIdData: action.alldata };
        case "EmptyUserDetail":
            return { ...state, userIdData: [] };
        default:
            return state;
    }
}

export default UserReducers;