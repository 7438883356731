const initialState = {
    unitTitlesData:[],
    unitTitleLoadingData:true
}

const UnitTitleReducers = (state = initialState,action)=>{
    switch(action.type){
        case "GetUnitTitles":
            return {...state,unitTitlesData:action.alldata,unitTitleLoadingData:action.unitTitleLoadingData};
        default:
        return state;
    }
}

export default UnitTitleReducers;