const initialState = {
    announcementData: [],
    announcementLoadingData: true,
    allDataByUserId: []
}

const AnnouncementReducers = (state = initialState, action) => {
    switch (action.type) {
        case "GetAllAnnoucement":
            return {
                ...state,
                announcementData: action.alldata,
                announcementLoadingData: action.announcementLoadingData

            };
        case "GetAllAnnouncementByUserId":
            return {
                ...state,
                allDataByUserId: action.allDataByUserId,
                announcementLoadingData: action.announcementLoadingData
            };
        default:
            return state;
    }
}

export default AnnouncementReducers;