const initialState = {
    categoryData: [],
    categoryLoadingData: true,
    categoryDetail: {}
}

const CategoryReducers = (state = initialState, action) => {
    switch (action.type) {
        case "GetAllCategories":
            return { ...state, categoryData: action.alldata, categoryLoadingData: action.categoryLoadingData };
        case "GetAllCategoriesById":
            return { ...state, categoryDetail: action.categoryDetail };
        default:
            return state;
    }
}

export default CategoryReducers;