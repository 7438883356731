const initialState = {
    feedbackPaginationData:[],
    feedbackLoadingData:true

}

const FeedbackReducers = (state = initialState,action)=>{
    switch(action.type){
        case "getPaginationFeedbacks":
            return {...state,feedbackPaginationData:action.alldata,    feedbackLoadingData:action.feedbackLoadingData};
        default:
        return state;
    }
}

export default FeedbackReducers;