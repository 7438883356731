const initialState = {
    unitData:[],
    unitIdData:[],
    unitLoadingData:true
}

const UnitReducers = (state = initialState,action)=>{
    switch(action.type){
        case "GetAllUnits":
            return {...state,unitData:action.alldata, unitLoadingData:action.unitLoadingData};
        case "GetUnitById":
            return {...state,unitIdData:action.alldata};
        default:
        return state;
    }
}

export default UnitReducers;