const initialState = {
    unitCategoryUnitContentLoadingData:true,
    unitCategoryUnitContentData:[],
    unitId:0

}

const UnitCategoryUnitContentReducers = (state = initialState,action)=>{
    switch(action.type){
        case "getAllUnitCategoryUnitContent":
            return {...state,unitCategoryUnitContentData:action.alldata, 
                unitCategoryUnitContentLoadingData:action.unitCategoryUnitContentLoadingData,
                unitId:action.unitId};
        case "emptyUnitCategoryUnitContent":
            return {...state,unitCategoryUnitContentData:undefined};
        default:
        return state;
    }
}

export default UnitCategoryUnitContentReducers;