const initialState = {
    categoryUnitsData: [],
    categoryUnitsByCategory: [],
    categoryUnitsByUserId: [],
    CategoryunitLoadingData: true,
    CategoryUnitLoadingByUserIdData: true,
    categoryUnitDetail:{},
    CategoryOrders:[],
}

const CategoryUnitReducers = (state = initialState, action) => {
    switch (action.type) {
        case "GetCategoryUnits":
            return { ...state, categoryUnitsData: action.alldata };
        case "GetCategoryUnitDetail":
            return { ...state, categoryUnitDetail: action.categoryUnitDetail }; Î
        case "GetCategoryUnitById":
            return { ...state, categoryUnitsByCategory: action.alldata, CategoryunitLoadingData: action.CategoryunitLoadingData };
        case "GetCategoryUnitByUserId":
            return { ...state, categoryUnitsByUserId: action.alldata, CategoryUnitLoadingByUserIdData: action.CategoryUnitLoadingByUserIdData };
        case "emptyCategoryUnitById":
            return { ...state, categoryUnitsByCategory: undefined, CategoryunitLoadingData: action.CategoryunitLoadingData };
        case "GetOrdersById":
            return {...state, CategoryOrders:action.payload}
        default:
            return state;
    }
}

export default CategoryUnitReducers;