const initialState = {
    reportData:[],
    dashboardLoadingData:true
}

const ReportReducers = (state = initialState,action)=>{
    switch(action.type){
        case "getAllReports":
            return {...state,reportData:action.alldata,dashboardLoadingData:action.dashboardLoadingData};
        default:
        return state;
    }
}

export default ReportReducers;